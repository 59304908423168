import React, { Fragment } from "react"

import styles from "./styles.module.css";
import {isMobile,isIOS} from 'react-device-detect';
import FormField from "../../shared/formField";
import CountryCode from "../../shared/CountryCode";
import ProceedButton from "../proceedButton";
import GreenShape from "../../images/sign-up-form-green-shape.png";
import { B2XDropdownOptionsSingular, ClientTypeOptions_B2B2C, ClientTypeOptions_B2C } from "../../common/dropdowns";
import { B2C_Tier, B2X, Client_Type, RecaptchaPublicKey } from "../../common/constants";
import { cloudInquiry, cloudSignUp } from "../../services/orderProcessing";
import { isValidationError } from "../../common/validation";
import AlertModal from "../../shared/alertModal";
import { Link, navigate } from "gatsby";
import { Helmet } from "react-helmet";
import GoogleReviewsSlider from "../googleReviewsSlider";
import logo from "../../images/wlogo.svg"

const queryString = require('query-string');

class InquiryForm extends React.Component {
    constructor(props) {
        super(props);

        const locationState = this.props.location && this.props.location.state && this.props.location.state;

        var b2x = B2X.B2C;
        var clientType = -999;

        if (locationState) {
          if (locationState.isWealth === true) {
            b2x = B2X.B2B2C;
          } else {
            b2x = B2X.B2C;
            switch (locationState.tier) {
              case B2C_Tier.HNI:
                clientType = Client_Type.B2C_HNI;
                break;
              case B2C_Tier.FAMILYOFFICE:
                clientType = Client_Type.B2C_FAMILYOFFICE;
                break;
            }
          }
        }

        console.log(locationState,b2x,clientType,'locationState');

        this.state = {
            form: {
                FirstName: '',
                LastName: '',
                CellCountryCode: '91',
                CellNum: '',
                EmailAddr: '',
                B2X: b2x,
                ClientType: clientType
            }
        }
    }

    componentDidMount() {
      this.getSearchParameters();
    }

    handleUpdate = (e, type, isNumber=false) => {
      const value = isNumber ? +e.target.value : (e.target.type === 'checkbox' ? e.target.checked : e.target.value);
      if (e.target.name === 'B2X') {
        this.updateB2X(value);
        return;
      }
      console.log(e,value,type);
      if (type) {
        this.setState({
          [type]: {
              ...this.state[type],
              [e.target.name]: value
          }
        })
      } else {
        this.setState({[e.target.name]: value});
      }
    }

    updateB2X = (value) => {
      this.setState({
        form: {
          ...this.state.form,
          B2X: value,
          ClientType: -999
        }
      })
    }

    getSearchParameters() {
        var searchParamsFromStorage = window.localStorage.getItem("urlSearchParams");
  
        var search = (this.props.location.search == '' || this.props.location.search == null) ? searchParamsFromStorage : this.props.location.search;
  
        const searchParams = queryString.parse(search, {arrayFormat: 'bracket'});
        this.setState({
          searchParams
        });
    }

    proceed = () => {
      var body = {...this.state.form};
  
      const validationError = isValidationError(body, [
        { name: 'FirstName', type: 'text' },
        { name: 'EmailAddr', type: 'text', validation: 'email'},
        { name: 'CellNum', type: 'text', validation: 'telno', countryCodeFieldName: 'CellCountryCode'},
        { name: 'ClientType', type: 'dropdown'}
      ]);

      console.log(validationError, body, 'proceed1');
  
      if (validationError || !body.AcceptedTermsAndConditions) {
        this.setState({dirty: true})
      } else {
        console.log(validationError, body, 'proceed2');
  
        this.setState({proceedLoading: true})
  
        var requestBody = {
          IdentityFields: {
            FirstName: body.FirstName,
            LastName: body.LastName,
            EmailAddr: body.EmailAddr || '',
            CellCountryCode: body.CellCountryCode,
            CellNum: body.CellNum
          },
          SearchParams: {
            ...this.state.searchParams
          },
          ClientType: body.ClientType,
          B2X: body.B2X,
          ExtraQuestions: {},
          InquiryType: this.props.inquiryType
        }

        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(RecaptchaPublicKey, {action: 'signup'}).then((recaptchaToken) => {
              requestBody.RecaptchaToken = recaptchaToken;
              console.log(recaptchaToken,'recaptchaToken')
              cloudInquiry(requestBody).then(({error,response}) => {
                console.log(JSON.stringify(requestBody), error,response, 'proceed3');
                if (error.status >= 400) {
                  this.setState({
                    proceedLoading: false,
                    signupErrorModal: {
                      visible: true,
                      headerText: 'Signup Failed',
                      bodyText: ['Error in signing up. Please try again or contact MProfit Support']
                    }
                  })
                } else {
                  if (response.IsSuccess) {
                    setTimeout(() => {
                        navigate('/demo/thank-you', {state: {email: requestBody.IdentityFields.EmailAddr}});
                    },1000)
                  } else {
                    this.setState({
                      proceedLoading: false,
                      signupErrorModal: {
                        visible: true,
                        headerText: 'Request Failed',
                        bodyText: [response.ErrorMessage]
                      }
                    })
                  }
                }
              })
            })
        });
      }
    }

    closeSignupErrorModal = (type) => {
        this.setState({
          signupErrorModal: undefined
        })
    }

    render() {
        return (
            <>
            <Helmet>
                <script id="grecaptcha" src="https://www.google.com/recaptcha/api.js?render=6Lf-654UAAAAAFBeUNWxDq4xYA2DQO-oUhuXCqpY"></script>
            </Helmet>
            {this.renderPage()}
            </>
        )
    }

    renderPage = () => {
        const { Graphic, headingText, bodyText } = this.props;

        const { signupErrorModal = {}, affiliateDiscount = 0, code, form } = this.state;

        const affiliateDiscountFinal = code ? 0 : affiliateDiscount;

        var isMobileFinal = false, isIOSFinal = false;

        if (typeof window !== "undefined") {
          isMobileFinal = isMobile;
          isIOSFinal = isIOS;
        }

        return (
            <>
                <AlertModal isOpen={signupErrorModal.visible} toggle={this.closeSignupErrorModal} headerText={signupErrorModal.headerText} bodyText={signupErrorModal.bodyText || []} option2={{text:'Okay',onClick:this.closeSignupErrorModal}}/>
                <div className={[styles.pageContainer].join(' ')}>
                    <div className={styles.topContainer}>
                        <div className={[styles.titleContainer,"d-flex flex-column flex-grow-11100"].join(" ")}>
                            <div className={styles.logoContainer}>
                                <Link to="/"><img className="w-100" src={logo} alt="" /></Link>
                            </div>
                            <div className={[styles.title].join(' ')}>
                                {headingText}
                            </div>
                            <div className={[styles.subtitle].join(' ')}>
                                {bodyText}
                            </div>
                        </div>
                        <div className={["flex-grow-11100", styles.topExtraContainer].join(" ")}>
                            
                        </div>
                    </div>
                    <div className={[styles.contentContainer].join(" ")}>
                        <div className={[styles.graphicContainer,styles.graphicContainerMargin,"flex-grow-11100"].join(" ")}>
                            <Graphic/>
                        </div>
                        <div className={[styles.formOuterContainer,"flex-grow-11100"].join(" ")}>
                            <Form state={this.state} handleUpdate={this.handleUpdate} proceed={this.proceed} affiliateDiscount={affiliateDiscountFinal} inquiryRequestTitle={this.props.inquiryRequestTitle} inquiryRequestCTA={this.props.inquiryRequestCTA} isWealth={form.B2X === B2X.B2B2C}/>
                        </div>
                    </div>
                    <GoogleReviewsSlider />
                </div>
            </>
        )
    }
}

const Form = ({state, handleUpdate, proceed, isWealth, inquiryRequestTitle, inquiryRequestCTA}) => (
    <Fragment>
        <div className={[styles.formContainer,"flex-grow-11100"].join(" ")}>
            <div className={[styles.formTitle].join(' ')}>
                {inquiryRequestTitle}
            </div>
            <div className={[styles.formRow].join(' ')}>
                <FormField fieldName="FirstName" label="First Name" sectionName={'form'} sectionValue={state} handleInputChange={handleUpdate} optional={false} dirty={state.dirty} perLine={2} skipBootstrapClasses={true} extraClass={styles.formField} extraClassLabel={styles.formFieldLabel} extraClassBar={styles.formFieldBar}/>
                <FormField fieldName="LastName" label="Last Name" sectionName={'form'} sectionValue={state} handleInputChange={handleUpdate} optional={false} dirty={state.dirty} perLine={2} skipBootstrapClasses={true} extraClass={styles.formField} extraClassLabel={styles.formFieldLabel} extraClassBar={styles.formFieldBar}/>
            </div>
            <div className={[styles.formRow].join(' ')}>
                <FormField fieldName="EmailAddr" label="Email" sectionName={'form'} sectionValue={state} handleInputChange={handleUpdate} optional={false} dirty={state.dirty} perLine={1} hideOptionalText={true} customValidation={'email'} skipBootstrapClasses={true} extraClass={styles.formField} extraClassLabel={styles.formFieldLabel} extraClassBar={styles.formFieldBar}/>
            </div>
            <div className={[styles.formRow].join(' ')}>
                <FormField fieldName={'CellCountryCode'} label={'Country'} sectionName={'form'} sectionValue={state} handleInputChange={handleUpdate} inputType="dropdown" options={CountryCode} optional={false} skipBootstrapClasses={true}  extraClass={styles.formFieldCountryCode} extraClassLabel={styles.formFieldLabel} extraClassBar={styles.formFieldBar}/>
                <FormField fieldName={'CellNum'} label={'Tel Number'} sectionName={'form'} sectionValue={state} handleInputChange={handleUpdate} optional={false} dirty={state.dirty} sizeOverride={8} customValidation={'phone'} skipBootstrapClasses={true} extraClass={styles.formFieldPhoneNumber} extraClassLabel={styles.formFieldLabel} extraClassBar={styles.formFieldBar} countryCodeFieldName={'CellCountryCode'}/>
            </div>
            <div className={[styles.formRow].join(' ')}>
                <FormField fieldName={'B2X'} label={'Are you an Investor or a Wealth Professional?'} perLine={1} sectionName={'form'} sectionValue={state} handleInputChange={handleUpdate} inputType="dropdown" options={B2XDropdownOptionsSingular} optional={false} dirty={state.dirty} skipBootstrapClasses={true} extraClass={styles.formField} extraClassLabel={styles.formFieldLabel} extraClassBar={styles.formFieldBar}/>
            </div>
            <div className={[styles.formRow].join(' ')}>
              <FormField key={'ClientType_'+state.form.B2X} fieldName={'ClientType'} label={isWealth ? 'Please indicate your company profile' : 'What best describes you as an investor?'} perLine={1} sectionName={'form'} sectionValue={state} handleInputChange={handleUpdate} inputType="dropdown" options={isWealth ? ClientTypeOptions_B2B2C : ClientTypeOptions_B2C} optional={false} dirty={state.dirty} skipBootstrapClasses={true} extraClass={styles.formField} extraClassLabel={styles.formFieldLabel} extraClassBar={styles.formFieldBar}/>
            </div>
            <div className={[styles.termsOfUseRow].join(' ')}>
                <input className={["custom-input",styles.termsOfUseCheckbox,state.dirty && !state.form.AcceptedTermsAndConditions && styles.acceptTermsCheckboxInvalid].join(" ")} id="AcceptedTermsAndConditions" name="AcceptedTermsAndConditions" type="checkbox" checked={state.form.AcceptedTermsAndConditions} onChange={(event)=>handleUpdate(event,'form')} />
                <label className={[styles.acceptTermsCheckboxLabel].join(" ")} htmlFor="AcceptedTermsAndConditions"></label>
                <span className={styles.termsOfUseLabel}>I accept MProfit's <a href="/terms-of-use" target="_blank">Terms of Use</a> and <a href="/privacy" target="_blank">Privacy Policy</a></span>
            </div>
            <div className={[styles.proceedRow].join(' ')}>
                <ProceedButton buttonText={inquiryRequestCTA} key={'proceed-form'} borderRadius={4} onClick={proceed} disabled={false} loading={state.proceedLoading} btnExtraClasses={styles.proceedBtn}/>
            </div>
            <img src={GreenShape} className={styles.greenShape}/>
        </div>
    </Fragment>
)

export default InquiryForm
